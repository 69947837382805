import $ from "jquery";
import { initDropdown } from "../../components/archive-filter";
import { getBlockParams } from "../../js/lib/utils";

let svg = ` <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="17"
                height="10" viewBox="0 0 17 10" fill="none">
                <g clip-path="url(#clip0_8331_9823)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M15.9483 0.928646C16.3428 1.32309 16.3428 1.96269 15.9483 2.35713L9.21432 9.09114C8.81974 9.48558 8.18028 9.48558 7.7857 9.09114L1.05169 2.35713C0.657248 1.96269 0.657248 1.32309 1.05169 0.928646C1.44627 0.534201 2.08573 0.534201 2.48018 0.928646L8.50004 6.94851L14.5199 0.928646C14.9144 0.534201 15.5539 0.534201 15.9484 0.928646H15.9483Z"
                        fill="#262D33" />
                </g>
                <defs>
                    <clipPath id="clip0_8331_9823">
                        <rect width="16" height="9.77778" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>`;

const $doc = $(document);
let innerText = "";

let state = {
  filters: {
    category: [],
    option: [],
    radio: [],
  },
  taxonomies: {
    option: "",
    tabs: "",
    radio: "",
  },
  postType: "",
};

function getText() {
  if (state.postType === "lesson") {
    return "Explore by Session Type";
  } else if (state.postType === "performance" || state.postType === "community") {
    return "Explore by Genre";
  }

}

// Reset option filters to initial state
function resetOptionFilters() {
  state.option = [];
  innerText = getText();
  filterPosts(state);
  $(".js-option-filter .selected-option")
    .text(`${innerText}`)
    .data("value", "");
  $(".js-option-filter .selected-option").append(svg);
}

function filterPosts(state) {
  const postsList = $(".archive__grid");
  const blockParams = getBlockParams("archive_")[0] || {};

  if (state.postType === "performance") {
    const mainCategory = $(".js-archive-filter").attr("data-taxonomy");
    state.filters.category.push(mainCategory);
  }

  let orderBy = blockParams.orderby || "date";
  let order = blockParams.order || "ASC";
  let metaKey = "";

  if (state.postType === "lesson") {
    metaKey = "event_start_date";
  }

  const data = {
    action: "ajax_filter",
    postType: state.postType,
    category: state.filters.category,
    option: state.filters.option,
    radio: state.filters.radio,
    optionTaxonomy: state.taxonomies.option,
    radioTaxonomy: state.taxonomies.radio,
    tabTaxonomy: state.taxonomies.tabs,
    orderby: orderBy,
    order: order,
    meta_key: metaKey,
  };
  $.ajax({
    url: SITE.ajaxurl,
    type: "POST",
    data,
    beforeSend: function (xhr) {
      postsList.empty();
    },
    success: function (res) {
      if (res.length) {
        postsList.html(res);
      }
    },
    error: function (err) {
      console.log(err);
    },
  });
}
function selectCategoryFilter(state, element) {
  const termId = $(element).attr("data-taxonomy");
  if (element.hasClass("archive__tab--active")) {
    $(element).removeClass("archive__tab--active");
    state.filters.category = [];
    state.filters.option = [];
    filterPosts(state);
    return;
  }
  $(".js-tab-filter").removeClass("archive__tab--active");
  $(element).addClass("archive__tab--active");
  state.filters.category = [];
  state.filters.option = [];
  state.filters.category.push(termId);

  filterPosts(state);
}

function populateOptions(options) {
  let dropdown = $(".js-dropdown-options");
  dropdown.empty();

  dropdown.append(
    `<div class="dropdown-option" data-value="" tabindex="0">Explore All</div>`,
  );
  $.each(options, function (index, term) {
    dropdown.append(
      `<div class="dropdown-option" data-value=${index} tabindex="0">${term}</div>`,
    );
  });
}

function selectOptionFilter(value) {
  if (value === "") {
    state.filters.option = [];
    resetOptionFilters(); // Reset all filters in case all options are selected
  } else {
    state.filters.option = [value];
    filterPosts(state);
  }
}

function handleRadioToggle() {
  let selectedRadioValue = $('input[name="filter-dial"]:checked').val();

  if (selectedRadioValue === "on") {
    selectedRadioValue = "";
  }

  if (selectedRadioValue) {
    state.filters.radio = [selectedRadioValue];
  } else {
    state.filters.radio = [];
  }
  filterPosts(state);
}

// Initialization
export default function initClassesArchiveFilters() {
  const $archiveFilter = $(".js-archive-filter");
  state.postType = $archiveFilter.data("post-type");

  if (state.postType === "lesson") {
    state.taxonomies.option = "session-type";
    state.taxonomies.tabs = "label";
    state.taxonomies.radio = "availability";
  }
  if (state.postType === "performance") {
    state.taxonomies.option = "past-performance-genre";
    state.taxonomies.tabs = $(".js-archive-filter").attr("data-name");
    state.taxonomies.radio = $(".js-archive-filter__selectors").attr(
      "data-radio-name",
    );
  }
  if (state.postType === "community") {
    state.taxonomies.option = "community-category";
  //  state.taxonomies.tabs = $(".js-archive-filter").attr("data-name");
    state.taxonomies.radio = $(".js-archive-filter__selectors").attr(
      "data-radio-name",
    );
  }

  document.addEventListener("postsFound", (e) => {
    const { detail } = e;
    const options = detail.options;
    populateOptions(options);
  });

  $doc.on("change", ".js-option-filter", function (event, value) {
    event.preventDefault();
    selectOptionFilter(value);
  });

  $doc.on("click", ".js-tab-filter", function (event) {
    event.preventDefault();
    selectCategoryFilter(state, $(this));
    innerText = getText();
    $(".js-option-filter .selected-option")
      .text(`${innerText}`)
      .data("value", ""); // Reset option filter
    $(".js-option-filter .selected-option").append(svg);
  });

  $doc.on("change", 'input[name="filter-dial"]', function () {
    handleRadioToggle();
  });

  initDropdown(".js-option-filter");
}

$doc.ready(() => {
  if ($(".archive").length) {
    initClassesArchiveFilters();
  }
}); 

